<template>
    <!-- 实时数据》通道实时数据  -->
    <div id="app_cpid_real_data_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
            </div>

            <div class="head_top_title">通道实时数据</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <!-- 查询条件 -->
            <el-row id="query_condition_id"  class="cursor" style="margin:10px 0px;">
                <el-form  :model="form_data" :inline="true" label-width="50px" style="margin-bottom: 12px;">
                    <el-form-item label="类型">
                        <el-select v-model="form_data.type"  size="mini" style="width:80px"> 
                            <el-option label="按秒" :value="0"></el-option>
                            <el-option label="按分" :value="1"></el-option>
                            <el-option label="按时" :value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="通道">
                        <el-select v-model="form_data.channel_id" filterable clearable size="mini" style="width:260px"> 
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="起始时间" label-width="80px">
                        <el-date-picker
                            v-model="form_data.daytime"
                            type="datetime"
                            placeholder="选择日期时间" size="mini" style="width:190px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="getData()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            
             <!-- 主体内容 -->
            <el-row style="overflow: auto;" :style="{height: ((content_heigth-query_condition_height)+'px')}">
                <!-- 图表 -->
                <div id="image_table_id" style="width: 100%;height:400px;margin-top:0px;">
                </div>   

            </el-row>

        </el-row>
    </div>
</template>
<script>
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';
import * as echarts from 'echarts';

export default {
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            channelDataList:[],//通道列表数据-请求接口获取

            query_condition_height:40,//查询条件的高度

            form_data:{
                type:1,//类型
                channel_id:"",//通道id
                daytime:new Date(new Date().getTime()-5*60*1000),//日期
            },
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        // this.pageSize = this.$options.data().pageSize;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();

        //获取通道列表数据-请求接口获取
        this.getChannelDataList();
        
        //查询
        //this.getData();
    },

    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.$nextTick(function(){
                //查询条件的高度
                var query_condition = document.getElementById('query_condition_id');
                if(!API.isEmtry(query_condition)){
                    this.query_condition_height = query_condition.clientHeight;
                }
            });
        },

        //获取通道列表数据-请求接口获取
        getChannelDataList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },


        //查询
        getData(){
            var daytime = dateFtt(this.form_data.daytime,"yyyyMMddhhmmss");
            if(API.isEmtry(daytime)){
                this.$message.error("请选择时间");
                return;
            }
            if(API.isEmtry(this.form_data.channel_id)){
                this.$message.error("请选择通道");
                return;
            }

            //请求接口
            API.DataRealServlet({
                param: "channelRealData",
                type:this.form_data.type,
                channel_id:this.form_data.channel_id,
                startTime:daytime
            }).then((res) => {
                if (res.recode === 0) {
                    // this.tableData = res.list;
                    this.imageTable(res.list);
                } 
            });
        },


        //图表
        imageTable(dataList){
            var id = "image_table_id";

            var xAxisData = [];
            var yAxisData = [];
            var yAxisData2 = [];
            var yAxisData3 = [];
            for(var i = 0 ;i<dataList.length;i++){
                var dataObj = dataList[i];
                
                xAxisData.push(dataObj.time);
                yAxisData.push(dataObj.mt_num);
                yAxisData2.push(dataObj.success_num);
                yAxisData3.push(dataObj.fail_num);
            }
            //console.log(xAxisData);
            // console.log(yAxisData);
            

            var title = "通道实时数据";//标题头

            var option = {
                //位置调整
                // grid:{
                //     left: '75',
                //     top: '15',//15
                //     right: '35',
                //     bottom: '30'
                // },
                title: {
                    text: title,
                    left: 10
                },
                toolbox: {
                    feature: {
                        dataZoom: {//数据区域缩放。目前只支持直角坐标系的缩放
                            yAxisIndex: false  //指定哪些 yAxis 被控制。如果缺省则控制所有的y轴。如果设置为 false 则不控制任何y轴。如果设置成 3 则控制 axisIndex 为 3 的y轴。如果设置为 [0, 3] 则控制 axisIndex 为 0 和 3 的y轴
                        },
                        saveAsImage: {//保存为图片。
                            pixelRatio: 2//保存图片的分辨率比例，默认跟容器相同大小，如果需要保存更高分辨率的，可以设置为大于 1 的值，例如 2
                        }
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {//位置调整
                    bottom: 90,
                },
                dataZoom: [{
                    type: 'inside'
                }, {
                    type: 'slider'
                }],
                //x轴数据
                xAxis: {
                    data: xAxisData,
                    silent: false,
                    splitLine: {
                        show: false
                    },
                    splitArea: {
                        show: false
                    }
                },

                yAxis: {
                    splitArea: {
                        show: false
                    }
                },
                legend: {
                    data: ['提交数',"成功数","失败数"],
                    itemGap: 5
                },
                series: [{
                    name: '提交数',
                    type: 'bar',
                    data: yAxisData,
                    color: ['#9DDADC'],
                    // Set `large` for large data amount
                    // large: true,
                    markPoint: {
                        data: [
                                {type: 'max', name: '最大值'},
                                {type: 'min', name: '最小值'}
                            ]
                    },
                    markLine: {
                        data: [
                            {type: 'average', name: '平均值'}
                        ]
                    }
                },{
                    name: '成功数',
                    type: 'bar',
                    data: yAxisData2,
                    color: ['#3CB371'],
                    // Set `large` for large data amount
                    // large: true,
                    markPoint: {
                        data: [
                                {type: 'max', name: '最大值'},
                                {type: 'min', name: '最小值'}
                            ]
                    },
                    markLine: {
                        data: [
                            {type: 'average', name: '平均值'}
                        ]
                    }
                },{
                    name: '失败数',
                    type: 'bar',
                    data: yAxisData3,
                    color: ['#FF0000'],
                    // Set `large` for large data amount
                    // large: true,
                    markPoint: {
                        data: [
                                {type: 'max', name: '最大值'},
                                {type: 'min', name: '最小值'}
                            ]
                    },
                    markLine: {
                        data: [
                            {type: 'average', name: '平均值'}
                        ]
                    }
                }]

            };  
            var chartDom = document.getElementById(id);
            var myChart = echarts.init(chartDom);
            myChart.setOption(option, true);
        },
        
        

        //返回
        goBack() {
            API.router_to("/index_real_data");
        }
    }
};

</script>

<style scoped>


</style>